import './LogoComponent.css';
import Background from '../Assets/Images/Background.jpg';
import Logo from '../Assets/Images/SmallWhiteLogo.png';
import { DividerComponent } from './Common/DividerComponent';

export const LogoComponent = (props) => {
    return (
        <header className='LogoComponent' style={{ backgroundImage: `url(${Background})` }}>
            <img src={Logo} alt='HeaderLogo' />
            <section className='letterSpacing'>
                {"PRIOR ATELIER".split("").map((letter) => {
                    return(<a>{letter}</a>)
                })}
            </section>
            <DividerComponent />
        </header>
    );
}