import './FooterComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faFacebook } from '@fortawesome/free-brands-svg-icons';
import SmallWhiteLogo from '../../Assets/Images/SmallWhiteLogo.png';
import { Link } from 'react-router-dom';

export const FooterComponent = (props) => {
    return (
        <footer className='footer'>
            <div>
                <Link to="https://www.facebook.com/people/Prior-Atelier/pfbid036ineJ8U2vu5NgxHrhY1E2xxuNpsLcttWgveFteuhcyw2E6ah6FMSgnANaqRWMeCul/"><FontAwesomeIcon icon={faFacebook} style={{ color: '#FFF', height: '40px' }} /></Link>
                <Link to="https://instagram.com/prioratelier"><FontAwesomeIcon icon={faInstagram} style={{ color: '#FFF', height: '40px' }} /></Link>
                <Link to="https://www.tiktok.com/@prioratelier"><FontAwesomeIcon icon={faTiktok} style={{ color: '#FFF', height: '40px' }} /></Link>
            </div>

            <div>
                <img src={SmallWhiteLogo} alt="Logo" style={{ height: "28px" }} />
            </div>

            <div>
                <span>Prior Atelier © Copyright 2024</span>
            </div>
        </footer>
    );
}