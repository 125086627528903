import React, { useState } from 'react';
import { sha512 } from 'js-sha512';
import SmallWhiteLogo from '../Assets/Images/SmallWhiteLogo.png';
import './LoginComponent.css';
import axios from 'axios';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

export const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);

    if (props.data.UserData) {
        window.location.replace("/");
        return;
    }

    async function handleLogin() {
        await axios.post('http://localhost:21487/Users/GetToken', {
            username: username,
            password: sha512(password)
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (response.status === 200) {
                if (rememberMe) {
                    localStorage.setItem("token", response.data.token);
                }
                sessionStorage.setItem("token", response.data.token);
                window.location.reload();
                return response.data;
            }
            localStorage.removeItem("token");
            console.error(response.data.Message);
            return null;
        })
        .catch(error => {
            console.error('Error en la solicitud:', error);
        });
    }

    return (
        <div className='LoginComponent'>
            <div className="LoginCard">
                <img src={SmallWhiteLogo} alt="Logo" />
                <input
                    type='text'
                    placeholder='Username'
                    className='LoginInput'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type='password'
                    placeholder='Password'
                    className='LoginInput'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div>
                    <input
                        type='checkbox'
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label>Remember me?</label>
                </div>
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};