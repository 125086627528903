import { NavBarComponent } from '../Components/Common/NavBarComponent';
import { ReserveComponent } from '../Components/ReservePage/ReserveComponent';

export const ReservePage = (props) => {
    return (
        <div className='ReservePage'>
            <NavBarComponent data={props.data} />
            <ReserveComponent data={props.data} />
        </div>
    );
}