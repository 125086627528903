import { Link } from 'react-router-dom';
import './ReserveComponent.css';
import React, { useState } from 'react';
import ResPicFemale from "../../Assets/Images/ReserveFemale.jpeg";
import ResPicMale from "../../Assets/Images/ReserveMale.jpeg";

export const ReserveComponent = (props) => {
    const { tempData, setTempData } = useState({
        Gender: -1,
        ServiceType: -1
    });
    const { windowState, setWindowState } = useState(0);
    const { reserveData, setReserveData } = useState({
        UserId: 1,
        GuidId: "",
        StartTime: "2024-08-10T10:00:00",
        EndTime: "2024-08-10T11:00:00",
        Finished: false,
        SoldProducts: [101, 102, 103],
        Services: [
            {
                Id: 1,
                ServiceName: "Haircut",
                Price: 25.00,
                SpendTime: "00:30:00",
                Gender: 0,
                Type: 1
            },
            {
                Id: 2,
                ServiceName: "Beard Trim",
                Price: 15.00,
                SpendTime: "00:15:00",
                Gender: 0,
                Type: 1
            }
        ]
    });

    function setGender(gender) {
        let temp = tempData;
        temp.Gender = gender;
        setTempData(temp);
        setWindowState(1);
    }

    function setServiceType(serviceType) {
        let temp = tempData;
        temp.ServiceType = serviceType;
        setTempData(temp);
        setWindowState(2);
    }

    function setWindow(st) {
        switch (st) {
            case 0:// Selección gender
                return (
                    <section className='ReserveComponent'>
                        <div className='SelectGender'>
                            <div onClick={() => { setGender(0) }} className='GenderCard'>
                                <img src={ResPicFemale} />
                                <span>Mujer</span>
                            </div>
                            <div onClick={() => { setGender(1) }} className='GenderCard'>
                                <img src={ResPicMale} />
                                <span>Hombre</span>
                            </div>
                        </div>
                    </section>
                );
            case 1:// Selección tipo de servicio
            case 2:// Selección servicio
            case 3:// Selección productos
            case 4:// Selección día y hora
            case 5:// Revisión servicios, productos y fecha
            case 6:// Aceptado y devuelta al home
                return (
                    <div className='ReserveComponent'>
                        salones<br />
                        salones<br />
                        salones<br />
                        salones<br />
                    </div>
                );
        }
    }
    return setWindow(0);
}