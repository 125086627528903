import { Link } from 'react-router-dom';
import './ReserveSectionComponent.css';
import React from 'react';

export const ReserveSectionComponent = (props) => {

    return (
        <div className='ReserveSectionComponent'>
            <span>Reservar tu cita aquí</span>
            <Link to='https://onlinebooking.ikosoft.com/311920A94A5D78959B150007D/ESP/'>
                <button>
                    Reservar
                </button>
            </Link>
        </div>
    );
}