import { LogoComponent } from '../Components/LogoComponent';

export const Error404Page = (props) => {
    window.history.replaceState(null, "Prior Atelier - Error 404", "/error404")
    return (
        <div>
            <LogoComponent data={props.data} />
            <a>Error</a>
        </div>
    );
}