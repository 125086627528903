import './NavBarComponent.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIdCard, faBookmark, faBook } from '@fortawesome/free-solid-svg-icons';
import SmallLogo from '../../Assets/Images/SmallLogo.png';

export const NavBarComponent = (props) => {
    const User = props.data.UserData;
    const Auth = User ? User.auth : 0;
    const NavBar = {
        Start: [
            {
                To: '/',
                Icon: <img src={SmallLogo} alt="Logo" />,
                Text: "Prior Atelier",
                Auth: 0
            }
        ],
        MiddleLeft: [
            {
                To: 'https://onlinebooking.ikosoft.com/311920A94A5D78959B150007D/ESP/',
                Icon: <FontAwesomeIcon icon={faBookmark} style={{ color: "#000" }} />,
                Text: 'Reservar',
                Auth: 0
            }
        ],
        MiddleAdmin: [
            {
                To: '/reserves',
                Icon: <FontAwesomeIcon icon={faBook} style={{ color: "#000" }} />,
                Text: 'Reservas',
                Auth: 2
            }
        ],
        End: [
            {
                To: User ? "/profile" : '/login',
                Icon: <FontAwesomeIcon icon={User ? faIdCard : faUser} style={{ color: "#000" }} />,
                Text: User ? "Profile" : 'Login',
                Auth: 0
            }
        ]
    }

    const GetItems = (array) => array.map((item) => {
        return (
            Auth >= item.Auth ?
                <Link to={item.To} className="link">
                    <span className="link-icon">
                        {item.Icon}
                    </span>
                    <span className="link-title">{item.Text}</span>
                </Link>
                :
                <></>)
    })
    return (
        <nav className="navbar">
            <div className='div'>
                <div className="menu">
                    {GetItems(NavBar.Start)}
                </div>
            </div>

            <div style={{ justifyContent: 'center' }}>
                <div className="menu">
                    {GetItems(NavBar.MiddleLeft)}
                </div>
            </div>

            {
                User && User.auth > 0 ?
                    <div style={{ justifyContent: 'center' }}>
                        <div className="menu">
                            {GetItems(NavBar.MiddleAdmin)}
                        </div>
                    </div>
                    :
                    <></>
            }

            <div style={{ justifyContent: 'end' }}>
                <div className="menu">
                    {GetItems(NavBar.End)}
                </div>
            </div>
        </nav>
    );
}