import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import axios from 'axios';

import './index.css';
import { Error404Page } from './Pages/Error404';
import { HomePage } from './Pages/Home';
import { Login } from './Components/LoginComponent';
import { ReservePage } from './Pages/Reserve';

const Index = () => {
    const [user, setUser] = useState(null);
    const props = {
        UserData: null
    }

    const token = localStorage.getItem("token");
    if (token && !sessionStorage.getItem('token')) {
        sessionStorage.setItem("token", token);
        window.location.reload();
    }

    useEffect(() => {
        async function getUser() {
            try {
                const response = await axios.get('http://localhost:21487/Users/GetByToken', {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': sessionStorage.getItem('token')
                    }
                });
                if (response.status === 200) {
                    setUser(response.data);
                } else {
                    console.error(response.data.Message);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        }

        getUser();
    }, []);
    if (user) {
        props.UserData = user;
    }

    const pageRouter = createBrowserRouter([
        {
            path: "*",
            element: <Error404Page data={props} />
        },
        {
            path: "/",
            element: <HomePage data={props} />
        },
        {
            path: "/home",
            element: <HomePage data={props} />
        },
        {
            path: "/login",
            element: <Login data={props} />
        },
        {
            path: "/reserve",
            element: <ReservePage data={props} />
        }
    ]);
    return <RouterProvider router={pageRouter} />;
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Index />
    </React.StrictMode>
);