import './ContactComponent.css';
import AndresContreras from '../Assets/Images/AndresContreras.jpg';

export const ContactComponent = (props) => {
    return (
        <section className='ContactComponent'>
            <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d790.1596655733157!2d-0.9920521227131!3d37.61066229975035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6343e6ad235237%3A0x3682e1657d7490dd!2sPrior%20Atelier!5e0!3m2!1ses!2ses!4v1720351676839!5m2!1ses!2ses" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            <div className="presentacion">
                <div className="div-3">
                    <div className="column">
                        <div className="foto-e-info">
                            <img
                                loading="lazy"
                                className="img-4"
                                src={AndresContreras}
                            />
                            <div className="nombre">
                                - Andrés Contreras Sánchez
                                <br />
                                - Más de 13 años de experiencia
                                <br />
                                - Teléfono: +34 868 041 596
                                <br />
                                - Whatsapp: +34 653 698 944
                            </div>
                        </div>
                    </div>
                    <div className="column-2">
                        <hr className="short-divider-tablet-mobile" />
                        <div className="biografia">
                            <p>
                                Especializado en cortes de vanguardia, Andres Contreras
                                Sánchez ha consolidado una destacada carrera como formador y
                                educador en el ámbito del estilismo. A lo largo de los últimos
                                años, ha dejado su huella en grandes firmas, desempeñándose
                                como formador de equipos y educador experto en gestión de
                                salones de belleza.
                            </p>
                            <p>
                                <br />
                            </p>
                            <p>
                                Sus habilidades excepcionales en cortes y color le han valido
                                reconocimiento en la industria. No solo ha sido un maestro en
                                la técnica, sino también un líder visionario. Como CEO de
                                Prior Atelier, quiere llevar la empresa a nuevas alturas y
                                consolidarla como referente en innovación y estilo.
                            </p>
                            <p>
                                <br />
                            </p>
                            <p>
                                Andrés Contreras Sánchez es más que un estilista excepcional,
                                es un líder inspirador que ha transformado la percepción del
                                estilismo contemporáneo, marcando tendencias y definiendo
                                estándares de excelencia en la industria.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
