import { NavBarComponent } from '../Components/Common/NavBarComponent';
import { LogoComponent } from '../Components/LogoComponent';
import { DividerComponent } from '../Components/Common/DividerComponent';
import { ReserveSectionComponent } from '../Components/ReserveSectionComponent';
import { CarouselComponent } from '../Components/CarouselComponent';
import { ContactComponent } from '../Components/ContactComponent';
import { FooterComponent } from '../Components/Common/FooterComponent';

export const HomePage = (props) => {
    return (
        <div>
            <LogoComponent data={props.data} />
            <NavBarComponent data={props.data} />
            <CarouselComponent />
            <ReserveSectionComponent />
            <DividerComponent />
            <ContactComponent />
            <FooterComponent />
        </div>
    );
}