import './CarouselComponent.css';
/*import { faScissors } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';*/
import Carousel1 from '../Assets/Images/Carousel1.jpg';
import Carousel2 from '../Assets/Images/Carousel2.jpg';
import Carousel3 from '../Assets/Images/Carousel3.jpg';
import Carousel4 from '../Assets/Images/Carousel4.jpg';
import Carousel5 from '../Assets/Images/Carousel5.jpg';
import Carousel6 from '../Assets/Images/Carousel6.jpg';
import { DividerComponent } from './Common/DividerComponent';

export const CarouselComponent = (props) => {
    return (
        <div className="CarouselComponent">
            <div className='CarouselContainer'>
                <div className="CarouselCard">
                    <img className="CarouselCardImage" src={Carousel1} alt="" />
                    {/*<div className="CarouselCardInfo">
                    <div className="CarouselCardSmallIcon">
                        <FontAwesomeIcon icon={faScissors} style={{ color: 'white' }} />
                    </div>

                    <h3 className="CarouselCardTitle">Lorem</h3>
                </div>*/}
                    <div className="CarouselCardBackdrop"></div>
                </div>
                <div className="CarouselCard">
                    <img className="CarouselCardImage" src={Carousel2} alt="" />
                    {/*<div className="CarouselCardInfo">
                    <div className="CarouselCardSmallIcon">
                        <FontAwesomeIcon icon={faScissors} style={{ color: 'white' }} />
                    </div>

                    <h3 className="CarouselCardTitle">Lorem</h3>
                </div>*/}
                    <div className="CarouselCardBackdrop"></div>
                </div>
                <div className="CarouselCard">
                    <img className="CarouselCardImage" src={Carousel3} alt="" />
                    {/*<div className="CarouselCardInfo">
                    <div className="CarouselCardSmallIcon">
                        <FontAwesomeIcon icon={faScissors} style={{ color: 'white' }} />
                    </div>

                    <h3 className="CarouselCardTitle">Lorem</h3>
                </div>*/}
                    <div className="CarouselCardBackdrop"></div>
                </div>
                <div className="CarouselCard">
                    <img className="CarouselCardImage" src={Carousel4} alt="" />
                    {/*<div className="CarouselCardInfo">
                    <div className="CarouselCardSmallIcon">
                        <FontAwesomeIcon icon={faScissors} style={{ color: 'white' }} />
                    </div>

                    <h3 className="CarouselCardTitle">Lorem</h3>
                </div>*/}
                    <div className="CarouselCardBackdrop"></div>
                </div>
                <div className="CarouselCard">
                    <img className="CarouselCardImage" src={Carousel5} alt="" />
                    {/*<div className="CarouselCardInfo">
                    <div className="CarouselCardSmallIcon">
                        <FontAwesomeIcon icon={faScissors} style={{ color: 'white' }} />
                    </div>

                    <h3 className="CarouselCardTitle">Lorem</h3>
                </div>*/}
                    <div className="CarouselCardBackdrop"></div>
                </div>
                <div className="CarouselCard">
                    <img className="CarouselCardImage" src={Carousel6} alt="" />
                    {/*<div className="CarouselCardInfo">
                    <div className="CarouselCardSmallIcon">
                        <FontAwesomeIcon icon={faScissors} style={{ color: 'white' }} />
                    </div>

                    <h3 className="CarouselCardTitle">Lorem</h3>
                </div>*/}
                    <div className="CarouselCardBackdrop"></div>
                </div>
            </div>
            <DividerComponent />
        </div>
    );
}